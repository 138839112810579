// Author: Ianoda aka Maxus -- maxus.blog
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3'; 
import numeral from 'numeral';
import { BigNumber } from '@ethersproject/bignumber';
import { Footer } from './footer.js';
import { InputView } from './inputview.js'
import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress, tokenNameAddyMap } from './tokenUtilities.js'
// CUSTOM components 
import './App.scss';
import { chainMap, enforceChain } from './components/ChainTools.js';
import { contractConfigs, tokenConfigs } from './components/contractConfigs.js';
import bg from './images/bg.png'
// MATH STUFF
const GEN = contractConfigs["generic"]["abi"]
const REFINE = contractConfigs["refinery"]["abi"]
const INGS = ["wheat","sugarcane","gseed","yomopu"]
const materials = [
  ["flour","wholeflour"],
  ["sugar", "brownsugar"],
  ["gcoil", "gpoil"],
  ["puju", "momi"]
]

function handleChainChange(chainId) {
      window.location.reload();
    }


function App() {

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])
  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  function cleanInputAmount (amt, approve=false, isRetry=false) {

    let decNum = 18
    let dec = BigNumber.from(10).pow(decNum)
    let cleanInputAmt = amt.toString();
    cleanInputAmt = cleanInputAmt.replaceAll(",","")
    let adjustor = (approve) ? 1 : -1

    let regDot = /[.]/g
    let locationOfDecimal = cleanInputAmt.search(regDot)

    let cleanInput;
    if (locationOfDecimal === -1) {
      cleanInput = cleanInputAmt + "0".repeat(decNum)
    } else {
      // cocksucking decimal time
      let inputIntegerString = cleanInputAmt.substring(0, locationOfDecimal);
      let inputDecimalString = cleanInputAmt.substring(locationOfDecimal + 1);
      console.log("integer : " + inputIntegerString)
      console.log("decimal : " + inputDecimalString)
      if (decNum > 0) { // this excludes ZOO from the adjustment
        if (inputDecimalString.length === decNum) {
          // probably hit max button
          cleanInput = inputIntegerString + inputDecimalString
        } else {
          // otherwise see how many decimal places are missing
          let decsMissing = decNum - inputDecimalString.length;

          // and add them
          cleanInput = inputIntegerString + inputDecimalString + "0".repeat(decsMissing)
        }
      } else {

      }
      
    }

    cleanInput = cleanInput.replace(/^0+/,'')
    let lastDigit
    if (isRetry) {
      
      lastDigit = cleanInput.substring(cleanInput.length - 1, cleanInput.length)
      //console.log(lastDigit)
      cleanInput = cleanInput.substring(0,cleanInput.length - 1)
      //console.log(cleanInput)
      lastDigit = (Number(lastDigit) > 0 ) ? (Number(lastDigit) - 1).toString() : "0"
      cleanInput = cleanInput + lastDigit
    }

    return cleanInput;
  }
  // this is a reference to the input field
  const theInputRef = React.createRef(0);
  // this is the web3 instance used throughout the dApp
  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')

  //--------CONTRACTS-------------------------------------------

  //--ingredients
  var bushel = new web3.eth.Contract(GEN, tokenConfigs["wheat"]["address"])
  var cane = new web3.eth.Contract(GEN, tokenConfigs["sugarcane"]["address"])
  var gseed = new web3.eth.Contract(GEN, tokenConfigs["gseed"]["address"])
  var yomopu = new web3.eth.Contract(GEN, tokenConfigs["yomopu"]["address"]) 
  var ingredientContract = [bushel, cane, gseed, yomopu]
  
  //--processed materials
  var flour = new web3.eth.Contract(REFINE, tokenConfigs["flour"]["address"])
  var wflour = new web3.eth.Contract(REFINE, tokenConfigs["wholeflour"]["address"])
  var sugar = new web3.eth.Contract(REFINE, tokenConfigs["sugar"]["address"])
  var bsugar = new web3.eth.Contract(REFINE, tokenConfigs["brownsugar"]["address"])
  var gcoil = new web3.eth.Contract(REFINE, tokenConfigs["gcoil"]["address"])
  var gpoil = new web3.eth.Contract(REFINE, tokenConfigs["gpoil"]["address"])
  var puju = new web3.eth.Contract(REFINE, tokenConfigs["puju"]["address"])
  var momi = new web3.eth.Contract(REFINE, tokenConfigs["momi"]["address"])

  var materialContract = [[flour, wflour], [sugar, bsugar], [gcoil, gpoil], [puju, momi]]


  //--grid
  var grid = new web3.eth.Contract(contractConfigs["thegrid"]["abi"], contractConfigs["thegrid"]["address"])




  const letItRip = () => {
    
  }

  const [step2ready, setStep2Ready] = React.useState(false)
  const [step3ready, setStep3Ready] = React.useState(false)
  const [step4ready, setStep4Ready] = React.useState(false)

  const [selectedIngredient, setSelectedIngredient] = React.useState(999);
  const selectIngredient = (n) => {
    return () => {
      setSelectedIngredient(n)
      setStep2Ready(true)
    }
  }

  const [refinesTo, setRefinesTo] = React.useState(["",""])
  const [displayIngredient, setDisplayIngredient] = React.useState("")
  React.useEffect(() => {
    if (selectedIngredient < 4) {
      setRefinesTo(materials[selectedIngredient])
      setDisplayIngredient(tokenConfigs[INGS[selectedIngredient]]["name"])
      ingredientContract[selectedIngredient].methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
        .then((bal) => {
          setBalanceOfSelectedIngredient(bal)
        })
    }
    
  }, [selectIngredient])

  const [selectedMaterial, setSelectedMaterial] = React.useState([999,999])
  const [balanceOfSelectedIngredient, setBalanceOfSelectedIngredient] = React.useState(0)
  const selectMaterial = (n) => {
    return () => {
      setSelectedMaterial([selectedIngredient, n])
      setStep3Ready(true)
    }
  }

  const [displayMaterial, setDisplayMaterial] = React.useState("")
  React.useEffect(()=>{
    if (selectedMaterial[0] < 4) {
      setDisplayMaterial(tokenConfigs[materials[selectedMaterial[0]][selectedMaterial[1]]]["name"])
    }
  },[selectedMaterial])

  const [dbal, setdBal] = React.useState("")

  React.useEffect(()=>{
    setdBal(formatTokenBalance(18,balanceOfSelectedIngredient))
  },[balanceOfSelectedIngredient])

  const [relevantAllowance, setRelevantAllowance] = React.useState(0)
  const [costPerIngredient, setCostPerIngredient] = React.useState(0)
  const [exchangeRate, setExchangeRate] = React.useState(0)
  const [tax, setTax] = React.useState(0)
  const [cost, setCost] = React.useState(0)
  const calculateProcess = () => {
    if (theInputRef.current !== null && typeof theInputRef.current !== "undefined") {

    var ref = theInputRef.current
    console.log(theInputRef.current)
    ingredientContract[selectedIngredient].methods.allowance(
      window.ethereum.selectedAddress, 
      tokenConfigs[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"]).call({from: window.ethereum.selectedAddress})
        .then((amt) => {
          setRelevantAllowance(amt)
          materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.costPerRawIngredient().call({from: window.ethereum.selectedAddress})
            .then((cpi) => {
              setCostPerIngredient(cpi)
              materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.exchangeRate().call({from: window.ethereum.selectedAddress})
                .then((rate) => {
                  setExchangeRate(rate)
                  materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.inputTaxBasisPoints().call({from: window.ethereum.selectedAddress})
                    .then((bp)=>{
                      setTax(bp)
                      materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.getCost(cleanInputAmount(ref.value))
                        .call({from: window.ethereum.selectedAddress})
                        .then((c)=>{
                          setCost(c)
                          setStep4Ready(true)
                        })
                    })
                })
            })
        })
      }

  }

  const [drelevantAllowance, setdRelevantAllowance] = React.useState("")
  
  React.useEffect(()=>{
    setdRelevantAllowance(formatTokenBalance(18,relevantAllowance))
  },[relevantAllowance])
  const [dcostPerIngredient, setdCostPerIngredient] = React.useState("")
   React.useEffect(()=>{
    setdCostPerIngredient(formatTokenBalance(18,costPerIngredient))
  },[costPerIngredient])
   const [dcost, setdCost] = React.useState("")
   const [doutput, setdOutput] = React.useState(0)
   React.useEffect(()=>{
    setdCost(formatTokenBalance(18,cost))
    setdOutput(calculateOutput(theInputRef.current.value,tax,exchangeRate))
  },[cost])

   const calculateOutput = (v,t,r) => {
    return (v - v*t/10000)*r 
   }

   const triggerApproval = () => {
    if (selectedIngredient < 4 && selectedMaterial[0] < 4) {
      setIsPending(true)
      ingredientContract[selectedIngredient].methods.approve(
        tokenConfigs[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"], 
        cleanInputAmount(100000))
        .send({from: window.ethereum.selectedAddress})
        .then(()=>{
          setIsPending(false)
          ingredientContract[selectedIngredient].methods.allowance(
            window.ethereum.selectedAddress, 
            tokenConfigs[materials[selectedMaterial[0]][selectedMaterial[1]]]["address"]).call({from: window.ethereum.selectedAddress})
            .then((amt) => {
              setRelevantAllowance(amt)
            })
        })
    }
    
   }

  const triggerProcess = () => {
    if (selectedIngredient < 4 && selectedMaterial[0] < 4 && theInputRef.current) {
      setIsPending(true)
      materialContract[selectedMaterial[0]][selectedMaterial[1]].methods.processRawIngredient(cleanInputAmount(theInputRef.current.value))
        .send({from: window.ethereum.selectedAddress, value: cost})
        .then(()=>{
          setIsPending(false)
          setStep2Ready(false)
          setStep3Ready(false)
          setStep4Ready(false)
        })
    }
  }


  return (
    <div className={"App" + " App--" + connected}>
    
    <header className="App-header">
      <img src={tokenConfigs["penerg"]["icon"]["default"]} className="App-logo" alt="logo" /> 
      <div className="App-name">The Refinery</div> 
    </header>
      <button className="metamask-btn" onClick={connectMM}>{mmBtnText}</button>
      <div className="App-preconnect">
        <h2>Connect to use The Refinery</h2>
      </div>
      <div className="App-core">
        <div className="step-1 select-ingredient">
          <h2>Step 1: Select an Ingredient to Refine</h2>
          <div className="ingredient" onClick={selectIngredient(0)}>BUSHEL</div>
          <div className="ingredient" onClick={selectIngredient(1)}>CANE</div>
          <div className="ingredient" onClick={selectIngredient(2)}>gSEED</div>
          <div className="ingredient" onClick={selectIngredient(3)}>YOMOPU</div>
        </div>
        <div className={"step-2 select-material step--" + step2ready}>
          <h2>Step 2: Select an output material</h2>
          <div className="material" onClick={selectMaterial(0)}>{refinesTo[0]}</div>
          <div className="material" onClick={selectMaterial(1)}>{refinesTo[1]}</div>
        </div>
        <div className={"step-3 amount-to-process step--" + step3ready}>
          <h2>Step 3: Enter how much ingredient you wish to process.</h2>
          <p>Balance: {dbal} {displayIngredient}</p>
          <input type="text" id="theInput" defaultValue="0" ref={theInputRef} />
          <button className="btn" onClick={calculateProcess}>Proceed</button>
        </div>
        <div className={"step-4 review step--" + step4ready}>
          <h2>Step 4: Review</h2>
          <p>Allowance: {drelevantAllowance} {displayIngredient}</p>
          <p>FTM Cost per Ingredient: {dcostPerIngredient} FTM</p>
          <p>Exchange Rate: {exchangeRate} {displayMaterial} per {displayIngredient}</p>
          <p>Ingredient Tax Rate: {tax/100}%</p>
          <p>Your Total FTM Cost: {dcost}</p>
          <p>Your Output: {doutput} {displayMaterial}</p>
          <button className="btn" onClick={triggerApproval}>Approve</button>
          <button className="btn" onClick={triggerProcess}>Process</button>
        </div>

        <div className="current-process">
          <h2>Your Selections</h2>
          <div className="selection">Refining: {displayIngredient}</div>
          <div className="selection">Target: {displayMaterial}</div>
        </div>

      </div>
      
      <div className={"pending pending--" + isPending}>Pending Transaction</div>

    <Footer />
   </div>
  );
}

export default App;
